<template>
  <div>
    <div>
      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12 w-100">
        <b-col
          cols="12"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
      </b-row>
    </div>
    <!-- Error page-->
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <!-- image -->
        <b-img
          fluid
          :src="img"
          class="img"
          alt="img"
        />
        <div class="w-100 text-center mt-2">
          <h2 class="mb-1 title">
            Our site is under maintenance
          </h2>
          <p class="mb-2 des">
            We need to maintain our service and could not complete your request. Please keep relax and don’t worry we will comeback later.
          </p>

          <user-hint />
        </div>
      </div>
      <live-chat />
    </div>
  <!-- / Error page-->
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BRow, BCol } from 'bootstrap-vue'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import UserHint from '@/components/UserHint.vue'
import LiveChat from '@/components/LiveChat.vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    LiveChat,
    BrandLogo,
    UserHint,
    BImg,
    BRow,
    BCol,
  },
  mixins: [envMixin],

  data() {
    return {
      img: require('@/assets/images/common/server-maintaince.png'),
    }
  },

  mounted() {
    if (this.isEcomdyPlatform && !this.isExclusivePartner) {
      this.$nextTick(() => {
        const plugin = document.createElement('script')
        plugin.setAttribute(
          'src',
          'https://wchat.freshchat.com/js/widget.js',
        )
        plugin.onload = () => {
          if (window.fcWidget) {
            window.fcWidget.init({
              token: 'b7c5a45f-b29b-4853-ac30-950b2842854a',
              host: 'https://wchat.freshchat.com',
              locale: 'en',
            })
          }
        }
        plugin.async = true
        return document.head.appendChild(plugin)
      })
    }
  },

  methods: {
    goToSupport() {
      if (window.fcWidget?.isOpen() !== true) {
        window.fcWidget.open()
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
  .img {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .title {
    font-size: 34px;
  }

  .des {
    line-height: 24px;
  }
</style>
